import { setActive } from '../../_config/utils';
import Base from '../../_config/base';

class Navigator extends Base {
  constructor(el) {
    super(el);

    this.tableRows = el.querySelectorAll('.navigator__table_row');
    this.applyBtn = el.querySelector('.apply-btn');
    this.pictures = el.querySelectorAll('.navigator__image_wrapper');
    this.tabs = el.querySelectorAll('.navigator__content_tabs-item');
    this.tables = el.querySelectorAll('.navigator__table_body');
    this.downloadFilesButtons = el.querySelectorAll('.download-file');

    // const loadMoreBtn = el.querySelector('.load-more');
    const fullList = [...this.tableRows];
    // const selectedRows = [];
    this.activeIndex = 'index-1';
    this.hoverActiveIndex = 'index-1';

    // let maxItems = 8;
    // const addItems = 2;
    // const activeList = fullList.slice(0, maxItems);

    // Add initial rows to the table
    // activeList.forEach((row) => {
    //   row.classList.add('active');
    // });

    // Add rows to the table
    // if (loadMoreBtn) {
    //   loadMoreBtn.addEventListener('click', () => {
    //     const addRows = fullList.length > maxItems + addItems
    //       ? fullList.slice(maxItems, maxItems + addItems)
    //       : fullList.slice(maxItems, fullList.length);

    //     maxItems += addRows;

    //     addRows.forEach((row) => {
    //       setTimeout(() => {
    //         row.classList.add('active');
    //       }, 300);
    //     });
    //   });
    // }

    fullList.forEach((tableRow) => {
      tableRow.addEventListener('click', () => {
        this.activeIndex = tableRow.getAttribute('data-index');
      });
    });

    this.pictures.forEach((picture) => {
      if (picture.getAttribute('data-index') === this.activeIndex) {
        picture.classList.remove('d-none');
      } else {
        picture.classList.add('d-none');
      }
    });

    fullList.forEach((tableRow) => {
      tableRow.addEventListener('mouseover', () => {
        this.hoverActiveIndex = tableRow.getAttribute('data-index');

        this.pictures.forEach((picture) => {
          if (picture.getAttribute('data-index') === this.hoverActiveIndex) {
            picture.classList.remove('d-none');
          } else {
            picture.classList.add('d-none');
          }
        });
      });

      tableRow.addEventListener('mouseleave', () => {
        this.pictures.forEach((picture) => {
          if (picture.getAttribute('data-index') === this.activeIndex) {
            picture.classList.remove('d-none');
          } else {
            picture.classList.add('d-none');
          }
        });
      });
    });

    this.tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        if (!tab.classList.contains('active')) {
          setActive(tab);
          this.tables.forEach((table) => {
            table.classList.toggle('active');
          });

          this.downloadFilesButtons.forEach((button) => {
            button.classList.toggle('active');
          });
        }
      });
    });

    // Add selected rows to array
    // fullList.forEach((tableRow, index) => {
    //   if (tableRow.getAttribute('data-status') !== 'free') {
    //     return;
    //   }

    //   tableRow.addEventListener('click', () => {
    //     if (tableRow.getAttribute('data-status') !== 'free') {
    //       return;
    //     }
    //     toggleClass(tableRow, 'selected');

    //     if (tableRow.classList.contains('selected')) {
    //       selectedRows.push(index);
    //     } else {
    //       const indexOfItem = selectedRows.indexOf(index);
    //       if (indexOfItem > -1) { // only splice array when item is found
    //         selectedRows.splice(indexOfItem, 1); // 2nd parameter means remove one item only
    //       }
    //     }
    //   });
    // });
  }
}

Navigator.className = 'Navigator';
export default Navigator;
