/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import Base from '../../_config/base';

class Kpi extends Base {
  constructor(el) {
    super(el);

    const kpiButtons = el.querySelectorAll('.kpi__card');

    if (window.innerWidth < 1024) {
      kpiButtons.forEach((btn) => {
        btn.onclick = () => {
          btn.classList.toggle('active');
        };
      });
    }
  }
}

Kpi.className = 'kpi';
export default Kpi;
