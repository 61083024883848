import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);

    this.hamburgerIcon = el.querySelector('#hamburger-icon');
    this.header = document.querySelector('.header');
    this.body = document.getElementById('body');
    this.menuButtons = document.querySelectorAll('.hamburger-menu__item');

    this.menuButtons.forEach((menuBtn) => {
      menuBtn.onclick = () => {
        // e.preventDefault();
        this.hamburgerIcon.classList.remove('active');
        this.body.classList.remove('overflow-x-hidden');
        this.header.classList.remove('active');
      };
    });

    this.hamburgerIcon.onclick = () => {
      // e.preventDefault();
      this.hamburgerIcon.classList.toggle('active');
      this.body.classList.toggle('overflow-x-hidden');

      if (this.header.classList.contains('hide')) {
        this.header.classList.remove('hide');

        setTimeout(() => {
          this.header.classList.add('active');
        }, 500);
      } else {
        this.header.classList.toggle('active');
      }
    };

    let lastScrollTop = 0;

    window.addEventListener('scroll', () => {
      if (this.header.classList.contains('active')) {
        return;
      }
      const position = window.scrollY || document.documentElement.scrollTop;
      if (position > lastScrollTop) {
        // downscroll code
        this.header.classList.add('hide');
      } else if (position < lastScrollTop) {
        // upscroll code
        this.header.classList.remove('hide');
      }
      lastScrollTop = position <= 0 ? 0 : position;
    }, false);
  }
}

Header.className = 'Header';
export default Header;
