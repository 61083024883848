/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { Loader } from '@googlemaps/js-api-loader';
// import { setActive } from '../../_config/utils';
import Base from '../../_config/base';

class Map extends Base {
  constructor(el) {
    super(el);

    this.config = {
      togglerSelector: 'location__nav-item',
      blockSelector: 'location__innerContent',
      distanceBtn: '.distanceBtn',
      // pointBtn: '.location__points_item',
      openClass: 'active',
    };

    // this.buttons = this.$el.getElementsByClassName(this.config.togglerSelector);
    // this.pointBtns = this.$el.querySelectorAll(this.config.pointBtn);
    this.markers = [];

    const locations = [
      ['Kirkbi AG, Baar', 47.18480575888132, 8.516511956786166, 'pin'],

      // ['Restaurant Story', 47.18958569978666, 8.51258579897327, 'restaurant'],
      // ['Neuhof Kulinaria, Beat Zürcher', 47.1881849362646, 8.516314256080816, 'restaurant'],
      // ['Restaurant im Park', 47.194525269241325, 8.52225808664639, 'restaurant'],
      // ['Restaurant Neumühle', 47.19540052407022, 8.524424916644936, 'restaurant'],
      // ['Restaurant Pizzeria Krone Baar', 47.19488834333281, 8.526181849843042, 'restaurant'],

      // ['Lidl Schweiz', 47.18511776929094, 8.518694083031136, 'shopping'],
      // ['Merkur', 47.184056962258516, 8.520410616355223, 'shopping'],
      // ['Volg Zug', 47.18274250691696, 8.52423109346712, 'shopping'],

      // ['talos-gym GmbH', 47.18980907746284, 8.516239770612891, 'sport'],
      // ['Personalworkout Fitness & Health AG', 47.18717887948289, 8.518511502975029, 'sport'],

      // ['Bahnhof Baar Neufeld', 47.18871673843257, 8.517829454523115, 'train'],
      // ['Bahnhof Baar Lindenpark', 47.18292492384261, 8.517565222276154, 'train'],
    ];

    const loader = new Loader({
      // apiKey: 'AIzaSyCiJFtIAtuuxxYJrOz6bDXcusynIy_eqJg',
      apiKey: 'AIzaSyDMctRX9CcP9ahFykNkjTKgTAYCYObQ5iM',
      version: 'weekly',
      libraries: ['places'],
    });

    const mapStyle = {
      center: {
        lat: 47.18480575888132,
        lng: 8.516511956786166,
      },
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [{
            saturation: '-100',
          },
          {
            lightness: '0',
          },
          {
            gamma: '0.65',
          },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{
            visibility: 'off',
          }],
        },
      ],
    };

    // eslint-disable-next-line prefer-arrow-callback
    loader.load().then((google) => {
      this.google = google;
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10.3,
        ...mapStyle,
      });

      this.setMarkers(this.map, google, locations);
      this.setMarkersVisibility(false);

      // give each marker a category and show only the ones that are selected in the filter
      // [...this.pointBtns].forEach((point) => {
      //   point.addEventListener('click', () => {
      //     const category = point.getAttribute('data-category');
      //     this.infoWindow.close();

      //     if (point.classList.contains('active')) {
      //       this.markers.forEach((currentMarker) => {
      //         currentMarker.setVisible(true);
      //       });
      //       point.classList.remove('active');
      //     } else {
      //       setActive(point);
      //       this.markers.forEach((currentMarker) => {
      //         const isCategoryEqualToActive = currentMarker.category ===
      //  category || currentMarker.category === 'pin';
      //         currentMarker.setVisible(isCategoryEqualToActive);
      //       });
      //     }
      //   });
      // });
    });

    // Micro / Macro buttons switcher
    // [...this.buttons].forEach((btn, index) => {
    //   btn.onclick = () => {
    //     setActive(btn);

    //     if (index === 0) {
    //       // MACRO
    //       this.map.setZoom(10.3);
    //       this.setMarkersVisibility(false);
    //     } else {
    //       // MICRO
    //       this.map.setZoom(14);
    //       this.setMarkersVisibility(true);
    //     }
    //   };
    // });
  }

  // Function to set the visibility of the marker depends on micro/macro view
  setMarkersVisibility(isViewMicro) {
    this.markers.forEach((marker) => {
      marker.category === 'pin'
        ? marker.setVisible(true)
        : !isViewMicro
          ? marker.setVisible(false)
          : marker.setVisible(true);
    });
  }

  // Function to create the markers array on the map and set the category to each marker
  setMarkers(gmap, googlemap, locations) {
    this.infoWindow = new googlemap.maps.InfoWindow();

    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i];

      const marker = new googlemap.maps.Marker({
        position: {
          lat: location[1],
          lng: location[2],
        },
        map: gmap,
        icon: new googlemap.maps.MarkerImage(
          `../assets/images/${location[3]}.svg`,
          null,
          new googlemap.maps.Point(0, 0),
        ),
        // shape,
        visible: false,
        category: location[3],
        title: `<a href="https://www.google.com/maps/search/?api=1&query=${location[0]}" target="_blank">${location[0]}</a>`,
      });

      this.markers.push(marker);

      // Add info window to each marker
      marker.addListener('click', () => {
        this.infoWindow.close();
        this.infoWindow.setContent(marker.getTitle());
        this.infoWindow.open(marker.getMap(), marker);
      });
    }
  }
}

Map.className = 'Map';
export default Map;
