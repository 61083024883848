import Button from './01-atoms/button/button';
// import Attachment from './01-atoms/inputs/attachment';
// import Range from './01-atoms/inputs/range';
// import Select from './01-atoms/inputs/select';
// import Share from './02-molecules/share/share';
// import Accordion from './02-molecules/accordion/accordion';
// import Alerts from './02-molecules/alerts/alerts';
import Location from './02-molecules/location/location';
import Map from './02-molecules/location/map';
// import Popup from './02-molecules/popup/popup';
import Slider from './02-molecules/slider/slider';
// import Teasers from './02-molecules/threeTeaser/teasers';
import Kpi from './02-molecules/kpi/kpi';
// import Filter from './02-molecules/filter/filter';
import Navigator from './02-molecules/navigator/navigator';
// import Dialogues from './02-molecules/dialogues/dialogues';
// import Contact from './02-molecules/contact/contact';
import Header from './03-organisms/header/header';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Button = Button.init();
  // Component.Range = Range.init();
  // Component.Dialogues = Dialogues.init();
  // Component.Share = Share.init();
  // Component.Accordion = Accordion.init();
  // Component.Alerts = Alerts.init();
  // Component.Popup = Popup.init();
  Component.Slider = Slider.init();
  // Component.Contact = Contact.init();
  // Component.Attachment = Attachment.init();
  // Component.Select = Select.init();
  Component.Header = Header.init();
  Component.Location = Location.init();
  Component.Navigator = Navigator.init();
  Component.Map = Map.init();
  // Component.Teasers = Teasers.init();
  // Component.Filter = Filter.init();
  Component.Kpi = Kpi.init();
});
