import Base from '../../_config/base';

class Location extends Base {
  constructor(el) {
    super(el);

    this.config = {
      togglerSelector: 'location__nav-item',
      blockSelector: 'location__innerContent',
      descriptionSelector: 'location__description',
      distanceBtn: '.distanceBtn',
      // pointWrapper: '.location__points',
      mapsImage: '.location__map',
      pointBtn: '.location__points_item',
      distanceTable: '.location__distances',
      openClass: 'active',
    };

    this.buttons = this.$el.getElementsByClassName(this.config.togglerSelector);
    this.description = this.$el.getElementsByClassName(this.config.descriptionSelector);
    this.distanceBtn = this.$el.querySelectorAll(this.config.distanceBtn);
    // this.pointWrapper = this.$el.querySelector(this.config.pointWrapper);
    this.distanceTable = this.$el.querySelectorAll(this.config.distanceTable);
    this.mapsImages = this.$el.querySelectorAll(this.config.mapsImage);

    [...this.buttons].forEach((btn) => {
      btn.addEventListener('click', this.triggerToggle.bind(this, btn));
    });

    [...this.distanceBtn].forEach((btn) => {
      btn.onclick = () => {
        [...this.distanceTable].forEach((table) => {
          table.classList.toggle(this.config.openClass);
          if (table.classList.contains(this.config.openClass)) {
            btn.getElementsByTagName('span')[0].innerText = 'DISTANZEN AUSBLENDEN';
          } else {
            btn.getElementsByTagName('span')[0].innerText = 'DISTANZEN ANZEIGEN';
          }
        });
      };
    });
  }

  triggerToggle(activeBtn) {
    if (activeBtn.classList.contains(this.config.openClass)) return;
    [...this.description, ...this.buttons, ...this.mapsImages].forEach((block) => {
      block.classList.toggle(this.config.openClass);
    });

    // activeBtn.classList.add(this.config.openClass);
  }
}

Location.className = 'Location';
export default Location;
